import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import MKBox from "../../../components/MKBox";

function Footer() {
  return (
    <MKBox component="footer">
      <Container>
        <Grid container spacing={3}>
          <Grid item xs={12} sx={{ textAlign: "center", my: 3 }}>
            All rights reserved. Copyright &copy; 2022, The Dandelion
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Footer;
