import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import PropTypes from "prop-types";
import { useState } from "react";
import emailjs from "emailjs-com";
import MuiLink from "@mui/material/Link";
import MKBadge from "../../../components/MKBadge";
import MKTypography from "../../../components/MKTypography";
import {
  DANDELION_CONTACT_US_ADDRESS,
  DANDELION_CONTACT_US_DESC,
  DANDELION_CONTACT_US_EMAIL,
  DANDELION_CONTACT_US_EMAIL_SENT,
  DANDELION_CONTACT_US_MAP_LINK,
  DANDELION_CONTACT_US_NUMBER,
  DANDELION_CONTACT_US_TITLE,
} from "../../../constants/homeScreenConstants";
import MKBox from "../../../components/MKBox";
import bgImage from "../../../assets/images/contactus.jpg";
import MKInput from "../../../components/MKInput";
import MKButton from "../../../components/MKButton";
import mapBg from "../../../assets/images/map_location.png";

// eslint-disable-next-line react/prop-types
function Contactus({ pageId }) {
  // eslint-disable-next-line no-unused-vars
  const [parentName, setParentName] = useState("");
  const [childName, setChildName] = useState("");
  const [contactNumber, setContactNumber] = useState("");
  const [childInfo, setChildInfo] = useState("");
  const [enableSend, setEnableSend] = useState(true);

  const onChange = (event) => {
    switch (event.target.id) {
      case "parent_name":
        setParentName(event.target.value);
        break;
      case "child_name":
        setChildName(event.target.value);
        break;
      case "contact_number":
        setContactNumber(event.target.value);
        break;
      case "child_info":
        setChildInfo(event.target.value);
        break;
      default:
        console.log("Unkown");
    }
    if (parentName === "" || childName === "" || contactNumber === "") {
      setEnableSend(true);
    } else {
      setEnableSend(false);
    }
  };

  const handleSend = () => {
    emailjs
      .send(
        "service_7q6x2vv",
        "template_7h8lo6t",
        {
          context: childName,
          parent_name: parentName,
          contact_no: contactNumber,
          children_info: childInfo,
        },
        "Sii6kDqM_BEOtji3M"
      )
      .then((r) => {
        if (r.status === 200) {
          // eslint-disable-next-line no-alert
          alert(DANDELION_CONTACT_US_EMAIL_SENT);
          setEnableSend(true);
          setParentName("");
          setChildInfo("");
          setChildName("");
          setContactNumber("");
        }
      });
  };

  return (
    <div id={pageId}>
      <MKBox component="section" py={0}>
        <Container>
          <Grid
            container
            item
            xs={12}
            lg={6}
            flexDirection="column"
            alignItems="center"
            sx={{ textAlign: "center", my: 6, mx: "auto", px: 0.75 }}
          >
            <MKBadge
              variant="contained"
              color="info"
              badgeContent="CONTACT US"
              container
              sx={{ mb: 2 }}
            />
            <MKTypography variant="h2" fontWeight="bold">
              {DANDELION_CONTACT_US_TITLE}
            </MKTypography>
            <MKTypography variant="body1" color="text">
              {DANDELION_CONTACT_US_DESC}
            </MKTypography>
          </Grid>
        </Container>
        <MKBox component="section" py={{ xs: 0, lg: 6 }}>
          <MKBox
            width="100%"
            bgColor="white"
            borderRadius="xl"
            shadow="xl"
            mb={6}
            sx={{ overflow: "hidden" }}
          >
            <Grid container spacing={2}>
              <Grid
                item
                xs={12}
                lg={5}
                position="relative"
                px={0}
                sx={{
                  backgroundImage: ({
                    palette: { gradients },
                    functions: { rgba, linearGradient },
                  }) =>
                    `${linearGradient(
                      rgba(gradients.dark.main, 0.8),
                      rgba(gradients.dark.state, 0.8)
                    )}, url(${bgImage})`,
                  backgroundSize: "cover",
                }}
              >
                <MKBox
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  width="100%"
                  height="100%"
                >
                  <MKBox py={6} pr={{ xs: 3, sm: 6 }} pl={{ xs: 3, sm: 12 }} my="auto">
                    <MKTypography variant="h3" color="white" mb={1}>
                      Contact Information
                    </MKTypography>
                    <MuiLink href={DANDELION_CONTACT_US_MAP_LINK} target="_blank" rel="noreferrer">
                      <MKBox
                        name="dandelion"
                        minHeight="40vh"
                        borderRadius="xl"
                        width="90%"
                        sx={{
                          backgroundImage: `url(${mapBg})`,
                          backgroundSize: "fill",
                          backgroundPosition: "top",
                          display: "grid",
                          placeItems: "center",
                          overflow: "hidden",
                        }}
                      />
                    </MuiLink>
                    <MKTypography variant="body2" color="white" opacity={0.8} mb={3} mt={2}>
                      Please Give us a miscall or drop a email. Fill the form in the right to be
                      reached
                    </MKTypography>
                    <MKBox display="flex" p={1}>
                      <MKTypography variant="button" color="white">
                        <i className="fas fa-phone" />
                      </MKTypography>
                      <MKTypography
                        component="span"
                        variant="button"
                        color="white"
                        opacity={0.8}
                        ml={2}
                        fontWeight="regular"
                      >
                        {DANDELION_CONTACT_US_NUMBER}
                      </MKTypography>
                    </MKBox>
                    <MKBox display="flex" color="white" p={1}>
                      <MKTypography variant="button" color="white">
                        <i className="fas fa-envelope" />
                      </MKTypography>
                      <MKTypography
                        component="span"
                        variant="button"
                        color="white"
                        opacity={0.8}
                        ml={2}
                        fontWeight="regular"
                      >
                        {DANDELION_CONTACT_US_EMAIL}
                      </MKTypography>
                    </MKBox>
                    <MKBox display="flex" color="white" p={1}>
                      <MKTypography variant="button" color="white">
                        <i className="fas fa-map-marker-alt" />
                      </MKTypography>
                      <MKTypography
                        component="span"
                        variant="button"
                        color="white"
                        opacity={0.8}
                        ml={2}
                        fontWeight="regular"
                      >
                        {DANDELION_CONTACT_US_ADDRESS}
                      </MKTypography>
                    </MKBox>
                  </MKBox>
                </MKBox>
              </Grid>
              <Grid item xs={12} lg={7}>
                <MKBox component="form" p={2} method="post">
                  <MKBox px={3} py={{ xs: 2, sm: 6 }}>
                    <MKTypography variant="h2" mb={1}>
                      Say Hi!
                    </MKTypography>
                    <MKTypography variant="body1" color="text" mb={2}>
                      We&apos;d like to talk with you.
                    </MKTypography>
                  </MKBox>
                  <MKBox pt={0.5} pb={3} px={3}>
                    <Grid container>
                      <Grid item xs={12} pr={1} mb={6}>
                        <MKInput
                          id="parent_name"
                          variant="standard"
                          label="My name is"
                          placeholder="Full Name"
                          value={parentName}
                          onChange={onChange}
                          InputLabelProps={{ shrink: true }}
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={12} pr={1} mb={6}>
                        <MKInput
                          id="child_name"
                          variant="standard"
                          label="I'm looking for admission of my young in"
                          placeholder="Name of the children"
                          value={childName}
                          onChange={onChange}
                          InputLabelProps={{ shrink: true }}
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={12} pr={1} mb={6}>
                        <MKInput
                          id="contact_number"
                          variant="standard"
                          label="Contact Number"
                          value={contactNumber}
                          onChange={onChange}
                          placeholder="Please add your phone number here .."
                          InputLabelProps={{ shrink: true }}
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={12} pr={1} mb={6}>
                        <MKInput
                          id="child_info"
                          variant="standard"
                          label="Tell us more"
                          value={childInfo}
                          placeholder="Please add a brief description about your child like age, gender, previous school etc etcs"
                          InputLabelProps={{ shrink: true }}
                          onChange={onChange}
                          fullWidth
                          multiline
                          rows={6}
                        />
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      item
                      xs={12}
                      md={6}
                      justifyContent="flex-end"
                      textAlign="right"
                      ml="auto"
                    >
                      <MKButton
                        variant="gradient"
                        color="info"
                        disabled={enableSend}
                        onClick={handleSend}
                      >
                        Send Message
                      </MKButton>
                    </Grid>
                  </MKBox>
                </MKBox>
              </Grid>
            </Grid>
          </MKBox>
        </MKBox>
      </MKBox>
    </div>
  );
}
Contactus.prototype = {
  pageId: PropTypes.string.isRequired,
};
export default Contactus;
