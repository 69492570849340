import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import PropTypes from "prop-types";
import MKBadge from "../../../components/MKBadge";
import MKTypography from "../../../components/MKTypography";
import {
  DANDELION_ADMISSION_INFO_DESC,
  DANDELION_ADMISSION_INFO_TITLE,
  DANDELION_BRAND,
} from "../../../constants/homeScreenConstants";
import MKBox from "../../../components/MKBox";
import bgImage from "../../../assets/images/admission.jpg";

// eslint-disable-next-line react/prop-types
function Admission({ pageId }) {
  return (
    <div id={pageId}>
      <MKBox component="section" py={0}>
        <Container>
          <Grid
            container
            item
            xs={12}
            lg={6}
            flexDirection="column"
            alignItems="center"
            sx={{ textAlign: "center", my: 6, mx: "auto", px: 0.75 }}
          >
            <MKBadge
              variant="contained"
              color="info"
              badgeContent="ADMISSION"
              container
              sx={{ mb: 2 }}
            />
            <MKTypography variant="h2" fontWeight="bold">
              {DANDELION_ADMISSION_INFO_TITLE}
            </MKTypography>
          </Grid>
        </Container>
        <MKBox
          display="flex"
          alignItems="center"
          borderRadius="xl"
          my={2}
          py={12}
          sx={{
            backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
              `${linearGradient(
                rgba(gradients.dark.main, 0.8),
                rgba(gradients.dark.state, 0.8)
              )}, url(${bgImage})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        >
          <Container>
            <Grid container item xs={12} lg={6} sx={{ ml: { xs: 0, lg: 6 } }}>
              <MKTypography variant="h4" color="white" fontWeight="bold">
                {DANDELION_BRAND}
              </MKTypography>
              <MKTypography variant="h1" color="white" mb={1}>
                {DANDELION_ADMISSION_INFO_TITLE}
              </MKTypography>
              <MKTypography variant="body1" color="white" opacity={0.9} mb={2}>
                {DANDELION_ADMISSION_INFO_DESC}
              </MKTypography>
            </Grid>
          </Container>
        </MKBox>
      </MKBox>
    </div>
  );
}
Admission.prototype = {
  pageId: PropTypes.string.isRequired,
};
export default Admission;
