import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import {
  DANDELION_BRAND,
  DANDELION_MISSION_STRATEGY_DESC,
  DANDELION_MISSION_STRATEGY_FOUR,
  DANDELION_MISSION_STRATEGY_ONE,
  DANDELION_MISSION_STRATEGY_THREE,
  DANDELION_MISSION_STRATEGY_TITLE,
  DANDELION_MISSION_STRATEGY_TWO,
} from "../../../constants/homeScreenConstants";
import MKBox from "../../../components/MKBox";
import MKTypography from "../../../components/MKTypography";
import bgImage from "../../../assets/images/mission.jpg";

function Mission() {
  return (
    <>
      <MKBox component="section" py={5} />
      <MKBox
        display="flex"
        alignItems="center"
        borderRadius="xl"
        my={2}
        py={12}
        sx={{
          backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
            `${linearGradient(
              rgba(gradients.dark.main, 0.8),
              rgba(gradients.dark.state, 0.8)
            )}, url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <Container>
          <Grid container item xs={12} lg={6} sx={{ ml: { xs: 0, lg: 6 } }}>
            <MKTypography variant="h4" color="white" fontWeight="bold">
              {DANDELION_BRAND}
            </MKTypography>
            <MKTypography variant="h1" color="white" mb={1}>
              {DANDELION_MISSION_STRATEGY_TITLE}
            </MKTypography>
            <MKTypography variant="h5" fontWeight="medium" color="white" opacity={0.9} mb={2}>
              {DANDELION_MISSION_STRATEGY_DESC}
            </MKTypography>
            <MKTypography variant="body1" color="white" opacity={0.8} mb={2}>
              {DANDELION_MISSION_STRATEGY_ONE}
            </MKTypography>
            <MKTypography variant="body1" color="white" opacity={0.8} mb={2}>
              {DANDELION_MISSION_STRATEGY_TWO}
            </MKTypography>
            <MKTypography variant="body1" color="white" opacity={0.8} mb={2}>
              {DANDELION_MISSION_STRATEGY_THREE}
            </MKTypography>
            <MKTypography variant="body1" color="white" opacity={0.8} mb={2}>
              {DANDELION_MISSION_STRATEGY_FOUR}
            </MKTypography>
          </Grid>
        </Container>
      </MKBox>
    </>
  );
}

export default Mission;
