// Our Vision Data
export const DANDELION_BRAND = "The Dandelion";
export const DANDELION_BRAND_DESC = "Where we build future";
export const DANDELION_OUR_VISION = "OUR VISION";
export const DANDELION_CORE_VALUES = "Our Core Values";
export const DANDELION_OUR_VISION_EXTENDED = "Our Vision for Children";
export const DANDELION_OUR_VISION_DESCRIPTION =
  "Provide every Dandelion star with unlimited opportunities to explore oneself and carve out a unique identity while imbibing the core values.";
export const DANDELION_OUR_VISION_CORE_VALUES =
  "The school wishes to partner with the parents in overall growth of their offspring into well-groomed, confident and smart, who have the skills for collaborative learning ";
// Our Curriculum
export const DANDELION_OUR_CURRICULUM = "OUR CURRICULUM";
export const DANDELION_OUR_CURRICULUM_EXTENDED = "Our Curriculum for Children";
export const DANDELION_OUR_CURRICULUM_DESCRIPTION =
  "Our well researched and tailor-made curriculum is primarily based on play & primary school systems of Canada, Finland and Singapore with epitomises 'A Noble School' that provides an atmosphere to create the capacity for lifelong learning";
export const DANDELION_OUR_CURRICULUM_PILLARS = "Four Pillars of Primary Education";
export const DANDELION_OUR_CURRICULUM_PILLARS_FIRST = "LEARN TO KNOW";
export const DANDELION_OUR_CURRICULUM_PILLARS_FIRST_DESC =
  "Build a capability of learning anything which seems new/unknown.";
export const DANDELION_OUR_CURRICULUM_PILLARS_SECOND = "LEARN TO DO";
export const DANDELION_OUR_CURRICULUM_PILLARS_SECOND_DESC =
  "Build a capability to implement all learnings.";
export const DANDELION_OUR_CURRICULUM_PILLARS_THIRD = "LEARN TO LIVE TOGETHER";
export const DANDELION_OUR_CURRICULUM_PILLARS_THIRD_DESC =
  "Build a capability of living, caring, respecting and nurturing.";
export const DANDELION_OUR_CURRICULUM_PILLARS_FOUR = "LEARN TO BE";
export const DANDELION_OUR_CURRICULUM_PILLARS_FOUR_DESC =
  "Build a capability of begin self sufficient and purse happiness in life.";
export const DANDELION_OUR_CURRICULUM_PILLARS_FOOTER =
  "We offer a curriculum 'LEARNING-WITH-A-PURPOSE' which emphasizes Value-based education and the formation of good habits";
export const DANDELION_OUR_CURRICULUM_BELIEVE_HEADER =
  "We believe good and purposeful learning happens when.";
export const DANDELION_OUR_CURRICULUM_BELIEVE_ONE =
  "Child is imparted with the requisite skill to learn and apply, ";
export const DANDELION_OUR_CURRICULUM_BELIEVE_TWO =
  "Teacher adopts a thoughtful and mindful approach to assess the child's learning and ";
export const DANDELION_OUR_CURRICULUM_BELIEVE_THREE =
  "Parents are the partners in the transaction of our curriculum.";
// Skill development
export const DANDELION_OUR_SKILL_DEVELOPMENT_HEADER = "Dandelion Skill Development";
export const DANDELION_OUR_SKILL_DEVELOPMENT_TITLE_ONE = "Programme";
export const DANDELION_OUR_SKILL_DEVELOPMENT_DESC_ONE =
  "Programs aims at various skill identification like thinking, habits, creating and confidence to communicate";
export const DANDELION_OUR_SKILL_DEVELOPMENT_TITLE_TWO = "Assessment";
export const DANDELION_OUR_SKILL_DEVELOPMENT_DESC_TWO =
  "Our young ins would know the criteria of assessment which will not be based on rote learning and marks alone";
export const DANDELION_OUR_SKILL_DEVELOPMENT_TITLE_THREE = "Academic Transaction";
export const DANDELION_OUR_SKILL_DEVELOPMENT_DESC_THREE =
  "We would review of student on bases of activities. The aim is student has understood, absorbed, and developed an interest in subject. Students are assessed regularly so feedback loop can lead to correction and improvement";
export const DANDELION_OUR_SKILL_DEVELOPMENT_TITLE_FOUR = "Teacher-Parent Relation";
export const DANDELION_OUR_SKILL_DEVELOPMENT_DESC_FOUR =
  "Interactions will be practised seriously as we believe teacher and parents are partners in development of a child.";
// Mission strategy
export const DANDELION_MISSION_STRATEGY = "MISSION STRATEGY";
export const DANDELION_MISSION_STRATEGY_TITLE = "Mission Strategy";
export const DANDELION_MISSION_STRATEGY_DESC =
  "Our Mission Strategy is to achieve the below as a truly quality International Play School and we intend to s: ";
export const DANDELION_MISSION_STRATEGY_ONE = "A) Implement Quality Education practices.";
export const DANDELION_MISSION_STRATEGY_TWO =
  "B) Provide adequate, well-equipped learning facilities which are unmatched in the City of Dehradun";
export const DANDELION_MISSION_STRATEGY_THREE =
  "C) Endeavour to attract, employ and train a high calibre staff by offering attractive emoluments and providing a professionally challenged yet safe environment";
export const DANDELION_MISSION_STRATEGY_FOUR =
  "D) Foster a  meaningful  relationship and constant interaction with all stakeholders";

// Admissions
export const DANDELION_ADMISSION_INFO_TITLE = "Admission Information";
export const DANDELION_ADMISSION_INFO_DESC =
  "All children desirous of joining. The Dandelion are required to be registered first. This shall be followed by a shortlisting for offering admission by a judicious mix of personal interaction with parents and children, positioning evaluation for learning level at the time of entry to map the learning needs of the children towards appropriate intervention in school.";

// Admissions
export const DANDELION_CONTACT_US_TITLE = "Contact Us";
export const DANDELION_CONTACT_US_DESC = "Please find the contact info given below";
export const DANDELION_CONTACT_US_NUMBER = "(0135)-2691075";
export const DANDELION_CONTACT_US_EMAIL = "helpdesk@thedandelion.co.in";
export const DANDELION_CONTACT_US_ADDRESS = "54F5+VP6, Lachhiwala Range, Uttarakhand 248140";
export const DANDELION_CONTACT_US_MAP_LINK =
  "https://www.google.com/maps/@30.1754884,78.1115248,16.06z";
export const DANDELION_CONTACT_US_EMAIL_SENT =
  "Thanks ! Someone from The Dandelion will contact you shortly.";

// Amenities
export const DANDELION_MISSION_AMENITIES_TITLE = "Amenities Provided By Us";
export const DANDELION_MISSION_AMENITIES_DESC =
  "At Dandelion we provide amenities for student to grow into safe and comfortable environment";
export const DANDELION_MISSION_AMENITIES_OPERATION = "Dandelion Operations";
export const DANDELION_MISSION_AMENITIES_OPERATION_DESC =
  "Dandelion will adopt a mix of Class Room, Activity Centre and Learning Centre Plan Viz.";
export const DANDELION_MISSION_AMENITIES_CAFETERIA = "Dandelion Cafeteria";
export const DANDELION_MISSION_AMENITIES_CAFETERIA_DESC =
  "A Balanced, Scientific and Nutritious diet is envisaged. Designed by a qualified and certified dietitian. It is prepared in a modern kitchen under professional supervision. It is mandatory for all students at a reasonable cost.";
export const DANDELION_MISSION_AMENITIES_TRANSPORT = "Dandelion Transport";
export const DANDELION_MISSION_AMENITIES_TRANSPORT_DESC =
  "A safe and reliable transport is available on scheduled routes with GPS, Security Camera and speed governor. The route and stop are ascertained based on convenience and feasibility of the overall facility. Details of routes and fee thereof is available in office.";
