import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import PropTypes from "prop-types";
import MKBadge from "../../../components/MKBadge";
import {
  DANDELION_OUR_CURRICULUM,
  DANDELION_OUR_CURRICULUM_BELIEVE_HEADER,
  DANDELION_OUR_CURRICULUM_BELIEVE_ONE,
  DANDELION_OUR_CURRICULUM_BELIEVE_THREE,
  DANDELION_OUR_CURRICULUM_BELIEVE_TWO,
  DANDELION_OUR_CURRICULUM_DESCRIPTION,
  DANDELION_OUR_CURRICULUM_EXTENDED,
  DANDELION_OUR_CURRICULUM_PILLARS,
} from "../../../constants/homeScreenConstants";
import MKTypography from "../../../components/MKTypography";
import MKBox from "../../../components/MKBox";
import bgImage from "../../../assets/images/curriculum.jpg";
import DefaultInfoCard from "../../../examples/Cards/InfoCards/DefaultInfoCard";
import data from "./data/curriculumData";
import SkillDevelopment from "./SkillDevelopment";

// eslint-disable-next-line react/prop-types
function Curriculum({ pageId }) {
  const renderData = data.map(({ icon, title, description }) => (
    <>
      <DefaultInfoCard
        icon={icon}
        title={title}
        description={description}
        direction="center"
        key={title}
      />
      <br />
    </>
  ));
  return (
    <div id={pageId}>
      <MKBox component="section" py={12}>
        <Container>
          <Grid
            container
            item
            xs={12}
            lg={6}
            flexDirection="column"
            alignItems="center"
            sx={{ textAlign: "center", my: 0, mx: "auto", px: 0.75 }}
          >
            <MKBadge
              variant="contained"
              color="info"
              badgeContent={DANDELION_OUR_CURRICULUM}
              container
              sx={{ mb: 2 }}
            />
            <MKTypography variant="h2" fontWeight="bold">
              {DANDELION_OUR_CURRICULUM_EXTENDED}
            </MKTypography>
            <MKTypography variant="body1" color="text">
              {DANDELION_OUR_CURRICULUM_DESCRIPTION}
            </MKTypography>
          </Grid>
        </Container>
        <Grid container py={2} spacing={3} alignItems="center">
          <Grid item xs={12} lg={6}>
            <MKBox
              display={{ xs: "none", lg: "flex" }}
              width="calc(100% - 2rem)"
              height="calc(100vh - 2rem)"
              borderRadius="lg"
              ml={2}
              mt={2}
              sx={{ backgroundImage: `url(${bgImage})` }}
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={10}
            md={7}
            lg={6}
            xl={4}
            ml={{ xs: "auto", lg: 6 }}
            mr={{ xs: "auto", lg: 6 }}
          >
            <MKBox
              p={5}
              bgColor="white"
              borderRadius="xl"
              shadow="lg"
              display="flex"
              flexDirection="column"
              justifyContent="center"
              mt={{ xs: 5, sm: 10, md: 8 }}
              mb={{ xs: 12, sm: 5, md: 5 }}
              mx={3}
            >
              <MKBox
                variant="gradient"
                bgColor="info"
                coloredShadow="info"
                borderRadius="lg"
                display={{ xs: "none", lg: "flex" }}
                width="calc(100% - 2rem)"
                justifyContent="center"
                p={2}
                mx={2}
                mt={-8}
              >
                <MKTypography variant="body1" color="white">
                  {DANDELION_OUR_CURRICULUM_PILLARS}
                </MKTypography>
              </MKBox>
              <MKBox mt={2}>{renderData}</MKBox>
            </MKBox>
          </Grid>
        </Grid>
        <MKBox component="section" py={5}>
          <Container>
            <Grid
              container
              item
              xs={12}
              lg={6}
              justifyContent="center"
              sx={{ mx: "auto", textAlign: "center" }}
            >
              <MKTypography variant="h2">{DANDELION_OUR_CURRICULUM_BELIEVE_HEADER}</MKTypography>
              <MKTypography variant="h4" color="info" textGradient mb={2} py={1.5}>
                {DANDELION_OUR_CURRICULUM_BELIEVE_ONE}
                {DANDELION_OUR_CURRICULUM_BELIEVE_TWO}
                {DANDELION_OUR_CURRICULUM_BELIEVE_THREE}
              </MKTypography>
            </Grid>
          </Container>
        </MKBox>
        <SkillDevelopment />
      </MKBox>
    </div>
  );
}

Curriculum.prototype = {
  pageId: PropTypes.string.isRequired,
};

export default Curriculum;
