import {
  DANDELION_OUR_CURRICULUM_PILLARS_FIRST,
  DANDELION_OUR_CURRICULUM_PILLARS_FIRST_DESC,
  DANDELION_OUR_CURRICULUM_PILLARS_FOUR,
  DANDELION_OUR_CURRICULUM_PILLARS_FOUR_DESC,
  DANDELION_OUR_CURRICULUM_PILLARS_SECOND,
  DANDELION_OUR_CURRICULUM_PILLARS_SECOND_DESC,
  DANDELION_OUR_CURRICULUM_PILLARS_THIRD,
  DANDELION_OUR_CURRICULUM_PILLARS_THIRD_DESC,
} from "../../../../constants/homeScreenConstants";
import learnKnow from "../../../../assets/images/fourPillar/learn_know.png";
import learnDo from "../../../../assets/images/fourPillar/learn_do.png";
import learnBe from "../../../../assets/images/fourPillar/learn_be.png";
import learnLive from "../../../../assets/images/fourPillar/learn_live.png";

export default [
  {
    icon: learnKnow,
    title: DANDELION_OUR_CURRICULUM_PILLARS_FIRST,
    description: DANDELION_OUR_CURRICULUM_PILLARS_FIRST_DESC,
  },
  {
    icon: learnDo,
    title: DANDELION_OUR_CURRICULUM_PILLARS_SECOND,
    description: DANDELION_OUR_CURRICULUM_PILLARS_SECOND_DESC,
  },
  {
    icon: learnLive,
    title: DANDELION_OUR_CURRICULUM_PILLARS_THIRD,
    description: DANDELION_OUR_CURRICULUM_PILLARS_THIRD_DESC,
  },
  {
    icon: learnBe,
    title: DANDELION_OUR_CURRICULUM_PILLARS_FOUR,
    description: DANDELION_OUR_CURRICULUM_PILLARS_FOUR_DESC,
  },
];
