import { Icon } from "@mui/material";
import document from "./assets/document/registrationForm.pdf";

export const routes = [
  {
    name: "Vision",
    section: "section1",
    icon: <Icon>remove_red_eye</Icon>,
  },
  {
    name: "Curriculum",
    section: "section2",
    icon: <Icon>menu_book</Icon>,
  },
  {
    name: "Amenities",
    section: "section3",
    icon: <Icon>food_bank</Icon>,
  },
  {
    name: "Admission",
    section: "section4",
    icon: <Icon>book_online</Icon>,
  },
  {
    name: "Contact",
    section: "section5",
    icon: <Icon>perm_contact_calendar</Icon>,
  },
  {
    name: "Downloads",
    icon: <Icon>download</Icon>,
    collapse: [
      {
        name: "Registration Form",
        href: document,
      },
    ],
  },
];

export const mobileRoutes = [
  {
    name: "Downloads",
    icon: <Icon>download</Icon>,
    collapse: [
      {
        name: "Registration Form",
        href: document,
      },
    ],
  },
];
