import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import PropTypes from "prop-types";
import MKBox from "../../../components/MKBox";
import MKBadge from "../../../components/MKBadge";
import {
  DANDELION_CORE_VALUES,
  DANDELION_OUR_VISION,
  DANDELION_OUR_VISION_CORE_VALUES,
  DANDELION_OUR_VISION_DESCRIPTION,
  DANDELION_OUR_VISION_EXTENDED,
} from "../../../constants/homeScreenConstants";
import MKTypography from "../../../components/MKTypography";
import data from "./data/ourVision";
import TransparentCard from "../components/TransparentCard";

// eslint-disable-next-line react/prop-types
function Vision({ pageId }) {
  const renderData = data.map(({ id, animationData, name, description }) => (
    <Grid item xs={12} md={6} sx={{ mb: { xs: 3, lg: 0 } }} key={id}>
      <TransparentCard animationData={animationData} title={name} description={description} />
    </Grid>
  ));
  return (
    <div id={pageId}>
      <MKBox component="section" py={0}>
        <Container>
          <Grid
            container
            item
            xs={12}
            lg={6}
            flexDirection="column"
            alignItems="center"
            sx={{ textAlign: "center", my: 0, mx: "auto", px: 0.75 }}
          >
            <MKBadge
              variant="contained"
              color="info"
              badgeContent={DANDELION_OUR_VISION}
              container
              sx={{ mb: 2 }}
            />
            <MKTypography variant="h2" fontWeight="bold">
              {DANDELION_OUR_VISION_EXTENDED}
            </MKTypography>
            <MKTypography variant="body1" color="text">
              {DANDELION_OUR_VISION_DESCRIPTION}
            </MKTypography>
          </Grid>
        </Container>
        <Container sx={{ mt: { xs: 8, lg: 4 } }}>
          <Grid container spacing={3}>
            <Grid item xs={12} lg={3}>
              <MKBox position="sticky" top="100px" pb={{ xs: 2, lg: 6 }}>
                <MKTypography variant="h3" fontWeight="bold" mb={1}>
                  {DANDELION_CORE_VALUES}
                </MKTypography>
                <MKTypography variant="body2" mb={1}>
                  {DANDELION_OUR_VISION_CORE_VALUES}
                </MKTypography>
              </MKBox>
            </Grid>
            <Grid item xs={12} lg={9} sx={{ mt: 2, px: { xs: 0, lg: 8 } }}>
              <Grid container spacing={1}>
                {renderData}
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </MKBox>
    </div>
  );
}
Vision.prototype = {
  pageId: PropTypes.string.isRequired,
};

export default Vision;
