import Card from "@mui/material/Card";
import PropTypes from "prop-types";
import MKTypography from "../../../components/MKTypography";
import AnimationLoader from "./AnimationLoader";

// eslint-disable-next-line react/prop-types
function TransparentCard({ animationData, title, description }) {
  return (
    <Card
      sx={{
        background: "transparent",
        boxShadow: "none",
        overflow: "visible",
      }}
    >
      <AnimationLoader animationData={animationData} />
      <MKTypography variant="h4" gutterBottom>
        {title}
      </MKTypography>
      <MKTypography variant="body2" component="p" color="text" mb={3}>
        {description}
      </MKTypography>
    </Card>
  );
}
TransparentCard.prototype = {
  animationData: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
};
export default TransparentCard;
