import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import MKTypography from "../../../components/MKTypography";
import TransparentBlogCard from "../../../examples/Cards/BlogCards/TransparentBlogCard";
import programme from "../../../assets/images/skilldevelopment/program.jpg";
import assessment from "../../../assets/images/skilldevelopment/asssessment.jpg";
import transaction from "../../../assets/images/skilldevelopment/transcation.jpg";
import teacherParent from "../../../assets/images/skilldevelopment/parentteacher.jpg";
import MKBox from "../../../components/MKBox";
import {
  DANDELION_OUR_SKILL_DEVELOPMENT_DESC_FOUR,
  DANDELION_OUR_SKILL_DEVELOPMENT_DESC_ONE,
  DANDELION_OUR_SKILL_DEVELOPMENT_DESC_THREE,
  DANDELION_OUR_SKILL_DEVELOPMENT_DESC_TWO,
  DANDELION_OUR_SKILL_DEVELOPMENT_HEADER,
  DANDELION_OUR_SKILL_DEVELOPMENT_TITLE_FOUR,
  DANDELION_OUR_SKILL_DEVELOPMENT_TITLE_ONE,
  DANDELION_OUR_SKILL_DEVELOPMENT_TITLE_THREE,
  DANDELION_OUR_SKILL_DEVELOPMENT_TITLE_TWO,
} from "../../../constants/homeScreenConstants";

function SkillDevelopment() {
  return (
    <MKBox component="section" py={2}>
      <Container>
        <Grid container item xs={12} lg={6}>
          <MKTypography variant="h3" mb={6}>
            {DANDELION_OUR_SKILL_DEVELOPMENT_HEADER}
          </MKTypography>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} lg={3}>
            <TransparentBlogCard
              image={programme}
              title={DANDELION_OUR_SKILL_DEVELOPMENT_TITLE_ONE}
              description={DANDELION_OUR_SKILL_DEVELOPMENT_DESC_ONE}
              action={{
                type: "internal",
                route: "/pages/blogs/author",
                color: "info",
                label: "read more",
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={3}>
            <TransparentBlogCard
              image={assessment}
              title={DANDELION_OUR_SKILL_DEVELOPMENT_TITLE_TWO}
              description={DANDELION_OUR_SKILL_DEVELOPMENT_DESC_TWO}
              action={{
                type: "internal",
                route: "/pages/blogs/author",
                color: "info",
                label: "read more",
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={3}>
            <TransparentBlogCard
              image={transaction}
              title={DANDELION_OUR_SKILL_DEVELOPMENT_TITLE_THREE}
              description={DANDELION_OUR_SKILL_DEVELOPMENT_DESC_THREE}
              action={{
                type: "internal",
                route: "/pages/blogs/author",
                color: "info",
                label: "read more",
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={3}>
            <TransparentBlogCard
              image={teacherParent}
              title={DANDELION_OUR_SKILL_DEVELOPMENT_TITLE_FOUR}
              description={DANDELION_OUR_SKILL_DEVELOPMENT_DESC_FOUR}
              action={{
                type: "internal",
                route: "/pages/blogs/author",
                color: "info",
                label: "read more",
              }}
            />
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default SkillDevelopment;
