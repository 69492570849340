import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import PropTypes from "prop-types";
import MKBadge from "../../../components/MKBadge";
import MKTypography from "../../../components/MKTypography";
import MKBox from "../../../components/MKBox";
import {
  DANDELION_MISSION_AMENITIES_DESC,
  DANDELION_MISSION_AMENITIES_TITLE,
} from "../../../constants/homeScreenConstants";

import data from "./data/amenities";
import TransparentCard from "../components/TransparentCard";

// eslint-disable-next-line react/prop-types
function Amenities({ pageId }) {
  const renderData = data.map(({ id, animationData, name, description }) => (
    <Grid item xs={12} lg={4} key={id}>
      <MKBox position="relative" borderRadius="lg" shadow="md" p={2}>
        <TransparentCard description={description} title={name} animationData={animationData} />
      </MKBox>
    </Grid>
  ));
  return (
    <div id={pageId}>
      <MKBox component="section" py={0}>
        <Container>
          <Grid
            container
            item
            xs={12}
            lg={6}
            flexDirection="column"
            alignItems="center"
            sx={{ textAlign: "center", my: 6, mx: "auto", px: 0.75 }}
          >
            <MKBadge
              variant="contained"
              color="info"
              badgeContent="AMENITIES"
              container
              sx={{ mb: 2 }}
            />
            <MKTypography variant="h2" fontWeight="bold">
              {DANDELION_MISSION_AMENITIES_TITLE}
            </MKTypography>
            <MKTypography variant="body1" color="text">
              {DANDELION_MISSION_AMENITIES_DESC}
            </MKTypography>
          </Grid>
        </Container>
        <Container>
          <Grid container spacing={3}>
            {renderData}
          </Grid>
        </Container>
        <br />
      </MKBox>
    </div>
  );
}

Amenities.prototype = {
  pageId: PropTypes.string.isRequired,
};

export default Amenities;
