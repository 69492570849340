import cafeteria from "../../../../assets/images/amenties/cafeteria_data.json";
import operation from "../../../../assets/images/amenties/operations.json";
import transport from "../../../../assets/images/amenties/transport.json";
import {
  DANDELION_MISSION_AMENITIES_CAFETERIA,
  DANDELION_MISSION_AMENITIES_CAFETERIA_DESC,
  DANDELION_MISSION_AMENITIES_OPERATION,
  DANDELION_MISSION_AMENITIES_OPERATION_DESC,
  DANDELION_MISSION_AMENITIES_TRANSPORT,
  DANDELION_MISSION_AMENITIES_TRANSPORT_DESC,
} from "../../../../constants/homeScreenConstants";

export default [
  {
    id: 1,
    animationData: operation,
    name: DANDELION_MISSION_AMENITIES_OPERATION,
    description: DANDELION_MISSION_AMENITIES_OPERATION_DESC,
  },
  {
    id: 2,
    animationData: cafeteria,
    name: DANDELION_MISSION_AMENITIES_CAFETERIA,
    description: DANDELION_MISSION_AMENITIES_CAFETERIA_DESC,
  },
  {
    id: 3,
    animationData: transport,
    name: DANDELION_MISSION_AMENITIES_TRANSPORT,
    description: DANDELION_MISSION_AMENITIES_TRANSPORT_DESC,
  },
];
