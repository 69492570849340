import physical from "../../../../assets/images/ourvision/physical.json";
import respect from "../../../../assets/images/ourvision/respecting.json";
import reliance from "../../../../assets/images/ourvision/reliance2.json";
import care from "../../../../assets/images/ourvision/care.json";
import enviroment from "../../../../assets/images/ourvision/enviroment.json";
import habits from "../../../../assets/images/ourvision/habits.json";

export default [
  {
    id: 1,
    animationData: physical,
    name: "Physical and Mental Strength",
    description: "Promote all around strength",
  },
  {
    id: 2,
    animationData: respect,
    name: "Respect",
    description: "Promote respecting everyone irrelevant of caste, religion, species etc",
  },
  {
    id: 3,
    animationData: reliance,
    name: "Self Reliance",
    description: "Promote one's reliance on themselves and making them self sufficient",
  },
  {
    id: 4,
    animationData: care,
    name: "Care",
    description: "Promote sense of caring for everyone irrelevant of caste, religion, species etc",
  },
  {
    id: 5,
    animationData: enviroment,
    name: "Environment",
    description: "Promote an environment where children can grow their individuality",
  },
  {
    id: 6,
    animationData: habits,
    name: "Healthy Habits",
    description: "Promote and build healthy habits which can be followed through in life",
  },
];
