import PropTypes from "prop-types";
import { useLottie } from "lottie-react";

// eslint-disable-next-line react/prop-types
function AnimationLoader({ animationData }) {
  const options = {
    animationData,
    loop: true,
    autoplay: true,
  };
  const { View } = useLottie(options);
  return View;
}

AnimationLoader.prototype = {
  animationData: PropTypes.object.isRequired,
};

export default AnimationLoader;
