import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import bgImage from "../../assets/images/dandelion_bg_2.jpg";
import MKBox from "../../components/MKBox";
import dandelionLogo from "../../assets/images/logo_dandelion.png";
import Vision from "./sections/vision";
import Curriculum from "./sections/Curriculum";
import Mission from "./sections/Mission";
import Amenities from "./sections/Amenties";
import Admission from "./sections/Admission";
import Contactus from "./sections/contactus";
import Footer from "./components/footer";
import DefaultNavbar from "../../examples/Navbars/DefaultNavbar";
// eslint-disable-next-line import/named
import { mobileRoutes, routes } from "../../routes";
import { DANDELION_BRAND } from "../../constants/homeScreenConstants";

function Dandelion() {
  return (
    <>
      <DefaultNavbar routes={routes} mobileRoutes={mobileRoutes} brand={DANDELION_BRAND} sticky />
      <MKBox
        name="dandelion"
        minHeight="55vh"
        width="100%"
        sx={{
          backgroundImage: `url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "top",
          display: "grid",
          placeItems: "center",
        }}
      >
        <Container>
          <Grid container item xs={12} lg={7} justifyContent="center" mx="auto">
            <Card
              sx={{
                p: 3,
                mx: { xs: 2, lg: 3 },
                mt: 12,
                mb: 4,
                alignItems: "center",
                backgroundColor: ({ palette: { black }, functions: { rgba } }) =>
                  rgba(black.main, 0.5),
                backdropFilter: "saturate(50%) blur(s0px)",
                boxShadow: ({ boxShadows: { xxl } }) => xxl,
              }}
            >
              <img src={dandelionLogo} alt="logo" loading="lazy" />
            </Card>
          </Grid>
        </Container>
      </MKBox>
      <Card
        sx={{
          p: 2,
          mx: { xs: 2, lg: 3 },
          mt: -2,
          mb: 4,
          backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba(white.main, 0.5),
          backdropFilter: "saturate(200%) blur(10px)",
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >
        <Vision pageId="section1" />
        <Mission />
        <Curriculum pageId="section2" />
        <Amenities pageId="section3" />
        <Admission pageId="section4" />
        <Contactus pageId="section5" />
      </Card>
      <MKBox pt={6} px={1} mt={6}>
        <Footer />
      </MKBox>
    </>
  );
}
export default Dandelion;
